var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.orgNums || _vm.positions.length > 0)?_c('div',{staticClass:"fade-in position-relative",class:{ 'mt-n11': _vm.blockContent }},[(_vm.hider)?_c('div',{staticClass:"mat-blurred-bg"},[(!_vm.blockContent)?_c('div',_vm._l((_vm.companies),function(company,index){return _c('CompCard',{key:index,staticClass:"mx-5",attrs:{"title":company.title,"desc":company.desc,"logo":company.logo}})}),1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mat-card bs text-center mx-5 pt-6 pb-6 rounded",class:{
      'pb-9': _vm.carousel,
      'mat-card-opaque': _vm.hider || _vm.orgNums,
      'mat-img': _vm.image,
      'mat-pos': _vm.orgNums,
      dark: _vm.image,
    },style:({ 'background-image': 'url(' + _vm.image + ')' })},[_c('transition',{attrs:{"name":"carousel-fade","mode":"out-in"}},[_c('div',{key:_vm.title,class:{ 'mat-img-overlay': _vm.image }},[(_vm.avatar)?_c('div',[(_vm.avatar.type === 'img')?_c('Avatar',{attrs:{"link":_vm.CTA,"img":_vm.avatar.src,"size":"medium"}}):_vm._e(),(_vm.avatar.type === 'icon')?_c('div',{staticClass:"mat-card-icon-avatar d-flex justify-content-center align-items-center mx-auto"},[_c('i',{class:['ri-' + _vm.avatar.src]})]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mat-text-box mx-auto mw-300"},[_c('h3',{staticClass:"mat-card-title",class:{ big: _vm.image, medium: _vm.orgNums }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.desc))])]),(_vm.features)?_c('div',_vm._l((_vm.features),function(feature,index){return _c('div',{key:index,staticClass:"mat-feature-card d-flex align-items-center mt-3 justify-content-center"},[_c('h4',{staticClass:"m-0"},[_vm._v(_vm._s(feature.label))])])}),0):_vm._e(),(_vm.positions)?_c('div',{staticClass:"mb-5"},_vm._l((_vm.positions),function(position,index){return _c('JobCard',{key:index,staticClass:"mx-4 fade-up-ani",style:({ 'animation-delay': index / 10 + 's' }),attrs:{"title":position.title,"desc":position.desc,"link":position.link,"date":position.date,"orgId":_vm.orgId}})}),1):_vm._e(),_c('div',{class:{ 'mx-5': !_vm.image || _vm.orgNums }},[(typeof _vm.CTA === 'function')?_c('button',{staticClass:"btn btn-block",class:{
              'btn-black': !_vm.image && !_vm.orgNums,
            },on:{"click":_vm.CTA}},[(_vm.CTALabel)?_c('span',[_vm._v(_vm._s(_vm.CTALabel))]):_c('span',[_vm._v("Continue")])]):_vm._e(),(typeof _vm.CTA === 'string')?_c('router-link',{staticClass:"btn btn-block",class:{
              'btn-black': !_vm.image && !_vm.orgNums,
            },attrs:{"to":_vm.CTA,"tag":"button"}},[(_vm.CTALabel)?_c('span',[_vm._v(_vm._s(_vm.CTALabel))]):_c('span',[_vm._v("Continue")])]):_vm._e()],1),(_vm.CTA2)?_c('button',{staticClass:"btn btn-text mb-n3",on:{"click":_vm.CTA2}},[_vm._v(" Do later ")]):_vm._e()])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }