import Avatar from '@/components/Avatar/Avatar.vue'
import CompCard from '@/components/CompCard/CompCard.vue'
import JobCard from '@/components/JobCard/JobCard.vue'
import jobber from '@/assets/js/jobber.js'

export default {
    name: 'Mat',
    components: {
        Avatar,
        CompCard,
        JobCard
    },
    props: {
        avatar: Object,
        title: String,
        desc: String,
        CTA: [Function, String],
        CTA2: [Function, String],
        CTALabel: String,
        hider: Boolean,
        carousel: Boolean,
        blockContent: Boolean,
        image: String,
        orgNums: Array,
        orgId: String,
        orgName: String,
        features: Array
    },
    data: function () {
        return {
            positions: [],
            companies: []
        }
    },
    methods: {
        fetchJobs: function () {
            console.log(this.orgNums)
            jobber.get({
                orgNums: this.orgNums,
                location: 'Sverige',
                company: this.orgName
            }).then(jobs => {
                if (jobs.length > 0) this.positions = jobs.slice(0, 3)
                this.$forceUpdate()
            })
        },
    },
    created: function () {
        if (this.orgNums) this.fetchJobs()
    }
}