import axios from 'axios'

let queryCount = {}

export default {
    get: function ({
        query,
        location,
        company,
        orgNums
    }) {
        return new Promise((resolve) => {
            this.getFromAF(orgNums).then(afJobs => {
                //console.log('AF', afJobs)
                let makeCJSearch = (!afJobs || afJobs.length < 2)
                if (!makeCJSearch) resolve(afJobs)
                this.getFromCJ({
                    query,
                    location,
                    company
                }).then(cjJobs => {
                    //console.log('CJ', cjJobs)
                    if (!afJobs && !cjJobs) resolve(null)
                    if (afJobs && cjJobs) resolve(afJobs.concat(cjJobs))
                    if (afJobs && !cjJobs) resolve(afJobs)
                    if (!afJobs && cjJobs) resolve(cjJobs)
                })
            })
        })
    },
    formatJobs: function (jobs, type) {
        let formatedJobs = []
        if (type === 'AF') {
            jobs.forEach(job => {
                let desc = job.workplace_address.city ? job.workplace_address.city : job.workplace_address.municipality
                if (!desc) desc = job.workplace_address.country
                formatedJobs.push({
                    title: job.headline,
                    desc: desc,
                    link: job.webpage_url,
                    date: job.publication_date
                })
            })
        } else if (type === 'CJ') {
            jobs.forEach(job => {
                formatedJobs.push({
                    title: job.job_title,
                    desc: job.job_locations,
                    link: job.job_url,
                    date: job.job_date
                })
            })
        }
        return formatedJobs
    },
    getFromCJ: async function ({
        query,
        location,
        company
    }) {
        console.log('Making query for:', query, location, company)

        return new Promise((resolve) => {
            let data = 'page=1&location=' + location + '&query=' + (company ? company : query)
            console.log(data)
            axios.post('https://www.ilawtestsite.com/career/api.php', data, {
                    timeout: 2000,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=ISO-8859-15'
                    },
                })
                .then(response => {
                    let jobs = Object.values(response.data)
                    let onlyJobs = jobs.filter(val => typeof val === 'object')
                    let filteredJobs = []
                    if (company) filteredJobs = this.filterJobs(onlyJobs, company)
                    else filteredJobs = onlyJobs
                    let formatedJobs = this.formatJobs(filteredJobs, 'CJ')
                    if (company) resolve(formatedJobs)
                    else resolve(onlyJobs)
                })
                .catch(error => {
                    console.log(error === 'Error: timeout of 2000ms exceeded')
                    if (!queryCount[company]) queryCount[company] = 0
                    queryCount[company] += 1
                    if (error === 'Error: timeout of 2000ms exceeded' && queryCount[company] < 4) {
                        console.log('Fetching again')
                        this.get({
                            query,
                            location,
                            company
                        })
                    } else {
                        console.log('No jobs found or API down')
                        resolve(null)
                    }
                })
        })
    },
    getFromAF: function (orgNums) {
        return new Promise((resolve) => {
            if (!orgNums || orgNums.length === 0) resolve(null)
            let fetchCounter = 0
            let fetchedData = []
            orgNums.forEach(orgNum => {
                let config = {
                    method: 'get',
                    url: 'https://jobsearch.api.jobtechdev.se/search?employer=' + orgNum,
                    headers: {
                        'api-key': 'YidceDkxIlx4OGN0NlkiXHhkNlx4YWNRXHhhYlx4YTZRXHg4N1x4MWFceGUzXHhhMkpceGQ1XHg4Yyc',
                    },
                    data: null
                };
                axios(config)
                    .then((response) => {
                        fetchCounter++
                        fetchedData = fetchedData.concat(response.data.hits)
                        let formatedJobs = this.formatJobs(fetchedData, 'AF')
                        if (fetchCounter === orgNums.length) resolve(formatedJobs)
                    })
                    .catch(function (error) {
                        console.log(error);
                        fetchCounter++
                        if (fetchCounter === orgNums.length) resolve(null)
                    });
            })
        })
    },
    filterJobs: function (jobs, company) {
        console.log(jobs)
        return jobs.filter(job => job.job_company === company)
    }
}