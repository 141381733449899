import { render, staticRenderFns } from "./Mat.vue?vue&type=template&id=9fe06cac&scoped=true&"
import script from "./Mat.js?vue&type=script&lang=js&"
export * from "./Mat.js?vue&type=script&lang=js&"
import style0 from "./Mat.vue?vue&type=style&index=0&id=9fe06cac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe06cac",
  null
  
)

export default component.exports