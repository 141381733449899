import moment from 'moment';
import {
    ADD,
    db,
    firebase
} from '@/firebase.js'

export default {
    name: 'JobCard',
    props: {
        title: String,
        desc: String,
        date: String,
        avatar: Object,
        link: String,
        orgId: String,
    },
    data: function () {
        return {
            formatedDate: null,
        }
    },
    methods: {
        setDate: function () {
            this.formatedDate = moment(this.date).fromNow()
        },
        openJob: async function () {
            const query = {
                ...this.$route.query,
                job: this.link
            };
            this.$router.replace({
                query: query
            })
            await this.addJobActivity()
            setTimeout(() => {
                //window.open(this.link)
                window.location = this.link
            }, 100)
        },
        addJobActivity: function () {
            return new Promise(resolve => {
                let user = firebase.auth().currentUser;
                if (!user) return resolve(false)
                let col = db.collection('activity')
                let activityObj = {
                    date: new Date(),
                    uid: user.uid,
                    org: this.orgId,
                    job_title: this.title,
                    job_link: this.link,
                    type: 'job'
                }
                ADD(col, activityObj).then(() => resolve(true)).catch(() => {
                    resolve(false)
                })
            })
        }
    },
    created: function () {
        this.setDate()
    }
}